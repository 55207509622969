import React, { useEffect, useState, useRef, useCallback} from 'react';
import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider';
import { Blockquote, Button, Divider, Flex, Title, Text, Paper, Box, Modal, Group, Skeleton, Space} from '@mantine/core';
import { IconInfoCircle, IconArrowRight } from '@tabler/icons-react';
import { ImageSelectionGrid } from './ImageSelectionGrid';
import { EventDetailsForm } from './EventDetailsForm';
import { ConfirmFacesModalContent } from './ConfirmFacesForEvent';
import { NewPeopleModal } from './ConfirmNewPersonModal';
import { ImageData } from '../../types/ImageData';
import {FaceDataFromImages, NewPerson} from '../../types/Face';
import * as imageAPI from '../../api/imageAPI';
import ImageWithSkeleton from "../ImageWithSkeleton";
import {RemoveDuplicatesModal} from './RemoveDuplicateModal';
import axios from "axios";
import {
    GroupSuggestion,
    SuggestionAddImageToEvent,
    SuggestionMergeEvents,
    SuggestionNewEvent
} from "../../types/GroupInterfaces";
import {NewEvent} from "../aiGroupImages/GroupNewEvent";
// import {NewEvent} from "@components/GroupNewEvent";
import {AddToEvent} from "@components/aiGroupImages/GroupAddToEvent";
import {MergeEvents} from "@components/aiGroupImages/GroupMergeEvents";  // Add this import

interface GroupImagesManuallyProps {
    userId: string;
    auth: AuthenticationResultType;
}

export const GroupImagesManually = ({ userId, auth }: GroupImagesManuallyProps) => {
    // State management
    const [images, setImages] = useState<ImageData[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit] = useState(20);
    const [hasMore, setHasMore] = useState(true);
    const [selectedImages, setSelectedImages] = useState<string[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalPage, setModalPage] = useState(1);
    const [facesInImages, setFacesInImages] = useState<FaceDataFromImages | null>(null);
    const [newPeople, setNewPeople] = useState<NewPerson[]>([]);
    const [isPredicting, setIsPredicting] = useState(false);

    // Event details state
    const [eventDate, setEventDate] = useState<Date | null>(null);
    const [eventDescription, setEventDescription] = useState("");
    const [location, setLocation] = useState("");
    const [tags, setTags] = useState<string[]>([]);
    const [dateFormat, setDateFormat] = useState<'month' | 'day'>('month');
    const [isDateAI, setIsDateAI] = useState(false);
    const [isEventDescriptionAI, setIsEventDescriptionAI] = useState(false);
    const [isLocationAI, setIsLocationAI] = useState(false);

    // suggestions AI group
    const [suggestionsAiGroup, setSuggestionsAiGroup] = useState<GroupSuggestion[]>([]);
    const [isLoadingAiGroup, setIsLoadingAiGroup] = useState<boolean>(true);
    const [hasMoreSuggestionsAiGroup, setHasMoreSuggestionsAiGroup] = useState<boolean>(true);

    const [gridWidth, setGridWidth] = useState(0);
    const gridRef = useRef<HTMLDivElement>(null);

    // grahhhhh just ignoring the error because ive spent 4 hours on it and came up with nothing...
    // todo: fix actual issue and stop suppressing...
    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message.startsWith('ResizeObserver loop')) {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    // Only set up resize observer when modal is closed
    useEffect(() => {
        if (modalOpen) {
            return; // Don't observe when modal is open
        }

        const updateWidth = () => {
            if (gridRef.current) {
                setGridWidth(gridRef.current.offsetWidth);
            }
        };

        // Initial measurement
        updateWidth();

        // Set up resize observer
        const resizeObserver = new ResizeObserver(updateWidth);
        if (gridRef.current) {
            resizeObserver.observe(gridRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [modalOpen]); // Only re-run when modalOpen changes


    // Fetch images on mount and page change
    useEffect(() => {
        const fetchImages = async () => {
            const result = await imageAPI.withErrorHandling(
                () => imageAPI.fetchUngroupedImages(page, limit, userId, auth)
            );

            if (result) {
                setImages(prev => [...prev, ...result.images]);
                setHasMore(result.hasMore);
            }
        };

        fetchImages();
    }, [page]);

    // Handle AI predictions
    useEffect(() => {
        if (isDateAI && eventDate && eventDate.getDate() !== 1) {
            setDateFormat('day');
        }
    }, [isDateAI, eventDate]);

    const fetchAiGroupSuggestions = async () => {
        if (!hasMoreSuggestionsAiGroup) return;

        setIsLoadingAiGroup(true);
        try {
            let apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/event/suggestions";
            let id_token = auth.IdToken;

            const response = await axios.get(apiUrl, {
                params: {
                    sub: userId
                },
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });

            const responseBody = JSON.parse(response.data.body);

            const newEventSuggestions: SuggestionNewEvent[] = responseBody.new_event_suggestions.map((suggestion: any) => ({
                key: suggestion.key,
                score: suggestion.score,
                type: 'newEvent',
                images: suggestion.images
            }));

            const addToEventSuggestions: SuggestionAddImageToEvent[] = responseBody.add_to_event_suggestions.map((suggestion: any) => ({
                key: suggestion.key,
                score: suggestion.score,
                type: 'addImageToEvent',
                image: suggestion.image,
                event: suggestion.event
            }));

            const mergeEventsSuggestions: SuggestionMergeEvents[] = responseBody.merge_event_suggestions.map((suggestion: any) => ({
                key: suggestion.key,
                score: suggestion.score,
                type: 'mergeEvents',
                event1: suggestion.event1,
                event2: suggestion.event2
            }));

            const combinedSuggestions: GroupSuggestion[] = [...newEventSuggestions, ...addToEventSuggestions, ...mergeEventsSuggestions];

            if (combinedSuggestions.length === 0) {
                setHasMoreSuggestionsAiGroup(false);
            } else {
                setSuggestionsAiGroup(prevSuggestions => [...prevSuggestions, ...combinedSuggestions]);
            }

        } catch (error) {
            console.error('Error fetching images:', error);
            setHasMoreSuggestionsAiGroup(false);
        } finally {
            setIsLoadingAiGroup(false);
        }
    };

    const fetchAIPredictions = async () => {
        setIsPredicting(true);
        const result = await imageAPI.withErrorHandling(
            () => imageAPI.fetchAIPredictions(selectedImages, userId, auth)
        );

        if (result) {
            if (result.event_title) {
                setEventDescription(result.event_title);
                setIsEventDescriptionAI(true);
            }
            if (result.event_location) {
                setLocation(result.event_location);
                setIsLocationAI(true);
            }
            if (result.event_date) {
                setEventDate(new Date(result.event_date));
                setIsDateAI(true);
            }
            if (result.event_keywords) {
                setTags(result.event_keywords);
            }
        }
        setIsPredicting(false);
    };

    // Event handlers
    const handleImageSelect = (imageKey: string) => {
        setSelectedImages(prev =>
            prev.includes(imageKey)
                ? prev.filter(key => key !== imageKey)
                : [...prev, imageKey]
        );
    };

    const handleCreateEvent = async () => {
        if (selectedImages.length === 0) return;



        setLoading(true);
        setModalOpen(true);
        setModalPage(1);
        // const result = await imageAPI.withErrorHandling(
        //     () => imageAPI.fetchFacesInImages(selectedImages, userId, auth)
        // );
        //
        // if (result) {
        //     // setFacesInImages(result);
        //     setModalOpen(true);
        //     setModalPage(1);
        // }
        setLoading(false);
    };

    // Handle modal navigation
    const handleModalNext = async () => {
        const nextPage = modalPage + 1;
        if (nextPage === 3) {  // Changed from 3 to 4
            await fetchAIPredictions();
        }
        setModalPage(nextPage);
    };

    const confirmCreateEvent = async () => {
        const result = await imageAPI.withErrorHandling(
            () => imageAPI.createEvent({
                userId,
                imageKeys: selectedImages,
                eventDate,
                eventDescription,
                location,
                tags
            }, auth)
        );

        if (result) {
            setImages(prev => prev.filter(image => !selectedImages.includes(image.key)));
            setSelectedImages([]);
            setModalOpen(false);
            setEventDate(null);
            setEventDescription('');
            setLocation('');
            setTags([]);
            setModalPage(1);
        }
    };

    const postNewEvent = async (seed: string, selected: string[], unselected: string[]): Promise<boolean> => {
        try {
            const newEventUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/event/new";
            const id_token = auth.IdToken;

            let data = {
                sub: userId,
                include: selected,
                exclude: unselected
            }
            console.log(data)

            const response = await axios.post(newEventUrl,
                {
                    sub: userId,
                    include: selected,
                    exclude: unselected
                },
                {
                    headers: {
                        'Authorization': `Bearer ${id_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status === 200) {
                return true;
            } else {
                console.error(`Backend Error: Status code ${response.status}, Response data: ${JSON.stringify(response.data)}`);
                return false;
            }
        } catch (error) {
            console.error('Error creating event:', error);
            return false;
        }
    };

    const closeNewEventSuggestionAfterSuccessfulSubmit = (suggestion: SuggestionNewEvent) => {
        return async (seed: string, selected: string[], unselected: string[]) => {
            const success = await postNewEvent(seed, selected, unselected);

            if (success) {
                if (suggestionsAiGroup.length === 1) {
                    setIsLoadingAiGroup(true);
                    await fetchAiGroupSuggestions();
                    //fetchSuggestionsFromDemo()
                } else {
                    setSuggestionsAiGroup(prevSuggestions => prevSuggestions.filter(s => s.key !== suggestion.key));
                }
                // setSuggestions(prevSuggestions => prevSuggestions.filter(s => s.key !== suggestion.key));
                // if (suggestions.length === 0) {
                //     fetchSuggestionsFromDemo()
                // }
            }
        };
    };

    const suggestionComponents = suggestionsAiGroup.map((suggestion) => {
        if (suggestion.type === 'newEvent') {
            return (
                <NewEvent key={suggestion.key} group={suggestion}
                          onSubmit={closeNewEventSuggestionAfterSuccessfulSubmit(suggestion)}/>
            )
        } else if (suggestion.type === 'addImageToEvent') {
            return
        } else if (suggestion.type === 'mergeEvents') {
            return
        } else {
            console.log("suggestion type not handled");
            return null;
        }
    }).filter(Boolean);

    const EventSuggestionSkeleton = () => {
        return (
            <div>
                {[...Array(1)].map((_, index) => (
                    <Paper shadow="sm" radius="lg" withBorder p="xl" key={index} mt={index > 0 ? "lg" : undefined}
                           style={{backgroundColor: '#f0f0f0'}}>
                        <Skeleton height={28} width="40%" radius="xl"/>
                        <Skeleton height={1} width="100%" mt="sm"/>
                        <Flex
                            mt="md"
                            direction="row"
                            wrap="wrap"
                            gap={{base: '3px', sm: '3px'}}
                            justify={{sm: '3px'}}
                        >
                            {[...Array(5)].map((_, imgIndex) => (
                                <Skeleton
                                    key={imgIndex}
                                    width="calc((100% - 12px) / 5)"
                                    height={0}
                                    style={{paddingBottom: 'calc((100% - 12px) / 5)'}}
                                    radius="sm"
                                />
                            ))}
                        </Flex>
                        <Group justify="flex-end" gap="sm" mt="lg">
                            <Skeleton height={36} width={80} radius="md"/>
                            <Skeleton height={36} width={120} radius="md"/>
                        </Group>
                    </Paper>
                ))}
            </div>
        );
    };

    return (
        <div>
            <Flex direction="column" style={{ width: '100%', minHeight: '100vh', position: 'relative' }}>
                <Title order={1}>Manually Group Images</Title>
                <Divider mt="xs" />
                <Blockquote color="blue" icon={<IconInfoCircle />} mt="lg" mb="lg">
                    Select images that belong to the same event and click "Create Event" to group them together.
                    This manual method gives you full control over how your images are organized.
                </Blockquote>
                {isLoadingAiGroup ? (
                    <EventSuggestionSkeleton/>
                ) : suggestionComponents.length > 0 ? (
                    suggestionComponents
                ) : (
                    <Paper p="md" withBorder>
                        <Title order={3}>No more suggestions available</Title>
                        <p>No AI suggestions available.</p>
                    </Paper>
                )}
                <Space h="lg" />
                <div ref={gridRef}>
                    <ImageSelectionGrid
                        images={images}
                        selectedImages={selectedImages}
                        onImageSelect={handleImageSelect}
                        loading={loading}
                        hasMore={hasMore}
                        onLoadMore={() => setPage(prev => prev + 1)}
                        enableInfiniteScroll={!modalOpen} // Disable infinite scroll when modal is open
                    />
                </div>

                <Box style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.75)',
                    position: 'fixed',
                    bottom: 0,
                    zIndex: 2,
                    width: gridWidth || '100%'
                }}>
                    <Flex justify="center">
                        <Button
                            mt="sm"
                            mb="sm"
                            size="md"
                            onClick={handleCreateEvent}
                            disabled={selectedImages.length === 0}
                            style={{width: '50%'}}
                        >
                            {selectedImages.length !== 0
                                ? `Create Event with ${selectedImages.length} Selected Image${selectedImages.length !== 1 ? 's' : ''}`
                                : 'Select Images to Create an Event'
                            }
                        </Button>
                    </Flex>
                </Box>
            </Flex>

            <Modal
                opened={modalOpen}
                size="80%"
                onClose={() => setModalOpen(false)}
                title="Create New Event"
                style={{
                    width: '80vw',
                    height: '80vh',
                    maxWidth: 'none',
                }}
            >
                {modalPage === 1 && (
                    <>
                    <RemoveDuplicatesModal
                        userId={userId}
                        auth={auth}
                        selectedImages={selectedImages}
                        // onComplete={handleModalNext}
                    />
                        <Button
                            mt="xl"
                            fullWidth
                            onClick={handleModalNext}
                            rightSection={<IconArrowRight size={14} />}
                        >
                            Next
                        </Button>
                    </>

                )}

                {/*{modalPage === 2 && (*/}
                {/*    <>*/}
                {/*        <NewPeopleModal*/}
                {/*            userId={userId}*/}
                {/*            auth={auth}*/}
                {/*            newPeople={newPeople}*/}
                {/*            onUpdatePeople={setNewPeople}*/}
                {/*            onComplete={() => {*/}
                {/*                setModalPage(3);*/}
                {/*            }}*/}
                {/*        />*/}
                {/*        <Button*/}
                {/*            mt="xl"*/}
                {/*            fullWidth*/}
                {/*            onClick={handleModalNext}*/}
                {/*            rightSection={<IconArrowRight size={14} />}*/}
                {/*        >*/}
                {/*            Next*/}
                {/*        </Button>*/}
                {/*    </>*/}
                {/*)}*/}

                {modalPage === 2 && (
                    <>
                        <ConfirmFacesModalContent
                            userId={userId}
                            auth={auth}
                            imageKeys={selectedImages}
                            // facesInImages={facesInImages}
                            // onNewPersonAdded={(person) => setNewPeople(prev => [...prev, person])}
                        />
                        <Button
                            mt="xl"
                            fullWidth
                            onClick={handleModalNext}
                            rightSection={<IconArrowRight size={14} />}
                        >
                            Next
                        </Button>
                    </>
                )}

                {modalPage === 3 && (
                    <>
                        <Paper shadow="sm" radius="lg" withBorder p="md" style={{ backgroundColor: '#f0f0f0' }}>
                            <Text fw={500}>{"Images Included in Event:"}</Text>
                            <Flex
                                mt="xs"
                                direction="row"
                                wrap="wrap"
                                gap={{ base: '3px', sm: '3px' }}
                                justify={selectedImages.length < 4 ? 'center' : 'flex-start'}
                            >
                                {selectedImages.slice(0, 3).map(key => (
                                    <div
                                        key={key}
                                        style={{
                                            position: 'relative',
                                            width: 'calc((100% - 9px) / 4)',
                                            aspectRatio: 1 / 1
                                        }}
                                    >
                                        <ImageWithSkeleton
                                            src={images.find(image => image.key === key)?.thumbnail_url || ''}
                                            alt="Thumbnail"
                                            onClick={() => {}}
                                        />
                                    </div>
                                ))}
                                {selectedImages.length > 3 && (
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: 'calc((100% - 9px) / 4)',
                                            aspectRatio: 1 / 1
                                        }}
                                    >
                                        <ImageWithSkeleton
                                            src={images.find(image => image.key === selectedImages[3])?.thumbnail_url || ''}
                                            alt="Thumbnail"
                                            onClick={() => {}}
                                        />
                                        {selectedImages.length > 4 && (
                                            <div style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: 'white',
                                                zIndex: 10
                                            }}>
                                                +{selectedImages.length - 4}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Flex>
                        </Paper>

                        <EventDetailsForm
                            eventDate={eventDate}
                            eventDescription={eventDescription}
                            location={location}
                            tags={tags}
                            dateFormat={dateFormat}
                            isDateAI={isDateAI}
                            isEventDescriptionAI={isEventDescriptionAI}
                            isLocationAI={isLocationAI}
                            isPredicting={isPredicting}
                            onDateChange={(date) => {
                                setEventDate(date);
                                setIsDateAI(false);
                            }}
                            onDescriptionChange={(value) => {
                                setEventDescription(value);
                                setIsEventDescriptionAI(false);
                            }}
                            onLocationChange={(value) => {
                                setLocation(value);
                                setIsLocationAI(false);
                            }}
                            onTagsChange={setTags}
                            onDateFormatChange={setDateFormat}
                            onConfirm={confirmCreateEvent}
                        />
                    </>
                )}
            </Modal>
        </div>
    );
};