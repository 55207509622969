import React, { useState, useEffect } from 'react';
import { Title, Text, Button, Paper, Group, Flex, Divider } from '@mantine/core';
import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider';
import ImageWithSkeleton from '../ImageWithSkeleton';
import axios from 'axios';
import {ImageData} from '../../types/ImageData';

interface DuplicatePhotoGroup {
    key: string;
    duplicates: string[];
}

interface DuplicateGroup {
    key: string // this will be the image key for the original / main duplicate
    duplicates: ImageData[];
}

interface RemoveDuplicatesModalProps {
    userId: string;
    auth: AuthenticationResultType;
    selectedImages: string[];
}

export const RemoveDuplicatesModal = ({ userId, auth, selectedImages}: RemoveDuplicatesModalProps) => {
    const [loading, setLoading] = useState(true);
    const [duplicateGroups, setDuplicateGroups] = useState<DuplicateGroup[]>([]);

    useEffect(() => {
        fetchDuplicates();
    }, []);

    const fetchDuplicates = async () => {
        setLoading(true);
        console.log({
            sub: userId,
            image_keys: selectedImages
        });
        try {
            const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/duplicates/in-images";
            const response = await axios.post(apiUrl,
                {
                    sub: userId,
                    image_keys: selectedImages
                },
                {
                    headers: {
                        'Authorization': `Bearer ${auth.IdToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            console.log(response);
            console.log(JSON.parse(response.data.body));

            const duplicates: DuplicateGroup[] = typeof response.data.body === 'string'
                ? JSON.parse(response.data.body)
                : response.data.body;

            setDuplicateGroups(duplicates);
        } catch (error) {
            console.error('Error fetching duplicates:', error);
        } finally {
            setLoading(false);
        }
    };

    const mergeDuplicates = async (group: DuplicateGroup) => {
        try {
            const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/duplicates";
            await axios.post(apiUrl,
                {
                    sub: userId,
                    key: group.key
                },
                {
                    headers: {
                        'Authorization': `Bearer ${auth.IdToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            setDuplicateGroups(prev => prev.filter(g => g.key !== group.key));
        } catch (error) {
            console.error('Error merging duplicates:', error);
        }
    };

    const mergeAllDuplicates = async () => {
        try {
            for (const group of duplicateGroups) {
                await mergeDuplicates(group);
            }
            // onComplete();
        } catch (error) {
            console.error('Error merging all duplicates:', error);
        }
    };

    if (loading) {
        return (
            <div>
                <Title order={3} mb="md">Checking for Duplicates</Title>
                <Text size="sm">Looking for duplicate images in your selection...</Text>
            </div>
        );
    }

    // if (duplicateGroups.length === 0) {
    //     return (
    //         <div>
    //             <Title order={3} mb="md">No Duplicates Found</Title>
    //             <Text size="sm" mb="lg">No duplicate images were found in your selection.</Text>
    //             <Button onClick={onComplete}>Continue</Button>
    //         </div>
    //     );
    // }

    return (
        <div>
            <Flex justify="space-between" align="center" mb="md">
                <Title order={3}>Duplicate Images Found</Title>
                <Button
                    color="blue"
                    variant="light"
                    onClick={mergeAllDuplicates}
                >
                    Merge All Duplicates
                </Button>
            </Flex>

            <Text size="sm" mb="lg">
                The following duplicate images were found. You can merge them to keep the best quality version.
            </Text>

            {duplicateGroups.map((group, index) => (
                <Paper
                    key={group.key}
                    shadow="sm"
                    radius="md"
                    withBorder
                    p="md"
                    mt={index > 0 ? "md" : undefined}
                    style={{ backgroundColor: '#f0f0f0' }}
                >
                    <Text fw={500} mb="xs">Duplicate Set {index + 1}</Text>
                    <Divider mb="md" />

                    <Flex
                        direction="row"
                        wrap="wrap"
                        gap="xs"
                        justify="flex-start"
                    >
                        {group.duplicates.map((image, idx) => (
                            <div
                                key={idx}
                                style={{
                                    position: 'relative',
                                    width: 'calc((100% - 20px) / 3)',
                                    aspectRatio: '1 / 1'
                                }}
                            >
                                <ImageWithSkeleton
                                    src={image.thumbnail_url}
                                    alt={`Duplicate ${idx + 1}`}
                                    onClick={() => {}}
                                />
                            </div>
                        ))}
                    </Flex>

                    <Group justify="flex-end" mt="sm">
                        <Button
                            variant="light"
                            color="blue"
                            size="sm"
                            onClick={() => mergeDuplicates(group)}
                        >
                            Merge Images
                        </Button>
                    </Group>
                </Paper>
            ))}

            {/*<Group justify="flex-end" mt="xl">*/}
            {/*    <Button onClick={onComplete}>*/}
            {/*        Continue*/}
            {/*    </Button>*/}
            {/*</Group>*/}
        </div>
    );
};