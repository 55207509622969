// todo: this is the new interface I am currently implementing for person
export interface PersonBetter {
    seed_key: string;
    first: string;
    last: string;
    dob?: Date;
    profile_image_key?: string;
    profile_url?: string; // todo: add profile expire time?
    relationships?: Relationship[];
    life_events?: LifeEvent[];
    notes?: string | null;
}

export interface Person {
    id: string;
    name: string; // todo: i think i want to eventually have just first & last and whenever I want to use full name, just concatinate
    firstName?: string;
    lastName?: string;
    dateOfBirth: string; // todo: this should also be optional - ui should highly recommend but allow user to skip input if really want
    profilePictureUrl: string; // todo: this can probably be optional. Required fields should be id, first, & last. Everything else should be optional. This way relationships can have type & person
    relationships?: Relationship[];
    lifeEvents?: LifeEvent[];
    notes?: string | null;
    appearsIn?: ImageData[];
}

export enum LifeEventType {
    Birth = 'Birth',
    Death = 'Death',
    Marriage = 'Marriage',
    Divorce = 'Divorce',
    Graduation = 'Graduation',
    CareerChange = 'Career Change',
    Relocation = 'Relocation',
    BirthOfChild = 'Birth of Child',
    Retirement = 'Retirement',
    MilitaryService = 'Military Service',
    AwardRecognition = 'Award/Recognition',
    Other = 'Other'
}

export const LifeEventTypes = [
    LifeEventType.Birth,
    LifeEventType.Death,
    LifeEventType.Marriage,
    LifeEventType.Divorce,
    LifeEventType.Graduation,
    LifeEventType.CareerChange,
    LifeEventType.Relocation,
    LifeEventType.BirthOfChild,
    LifeEventType.Retirement,
    LifeEventType.MilitaryService,
    LifeEventType.AwardRecognition,
    LifeEventType.Other,
];

export interface LifeEvent {
    type: '' | LifeEventType;
    date: Date | null;
    description?: string;
    isLocked?: boolean;
}

export enum RelationshipType {
    HasChild = 'child',
    HasParent = 'Parent',
    HasSibling = 'Sibling',
    HasSpouse = 'Spouse'
    // todo: add more here?
}

export const RelationshipTypes = [
    RelationshipType.HasChild,
    RelationshipType.HasParent,
    RelationshipType.HasSibling,
    RelationshipType.HasSpouse,
];


// todo: enum relationship types
export interface Relationship {
    type: RelationshipType | null;
    name: string | null;
    person?: Person;
}