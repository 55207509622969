import React, { useEffect, useState } from 'react';
import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider';
import { SuggestedFaceContent } from "./SuggestedFaceCards";
import * as imageAPI from '../../api/imageAPI';
import {
    Blockquote,
    Divider,
    Title,
    Flex,
    Skeleton,
    Card,
    Paper,
    Text,
    Avatar
} from "@mantine/core";
import { Carousel } from '@mantine/carousel';
import { IconInfoCircle } from "@tabler/icons-react";
import axios from "axios";

export interface FaceInImage {
    face_key: string;
    face_url: string;
    face_rekognition_id: string;
}

export interface NamedFaceInImage extends FaceInImage {
    confirmed: true;
    person_key: string;
    person_name: string;
}

export interface UnnamedFaceInImage extends FaceInImage {
    confirmed: false;
    suggested_person_key?: string;
    suggested_person_name?: string;
}

export interface People {
    person_name: string;
    person_key: string;
    profile_url: string;
}

export interface NewPerson {
    person_key: string;
    person_name: string;
    first_name: string;
    last_name: string;
    profile_url: string;
    date_of_birth?: Date;
}

export interface FaceStack {
    faces: NamedFaceInImage[];
    stacked: boolean;
}

export interface FaceDataFromImages {
    people: People[];
    named_faces: NamedFaceInImage[];
    unnamed_faces: UnnamedFaceInImage[];
}

interface NewIdentifyFacesProps {
    userId: string;
    auth: AuthenticationResultType;
}

export const NewIdentifyFaces = ({ userId, auth }: NewIdentifyFacesProps) => {
    const [facesInImages, setFacesInImages] = useState<FaceDataFromImages | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [newPeople, setNewPeople] = useState<NewPerson[]>([]);

    useEffect(() => {
        const fetchFaces = async () => {
            try {
                setIsLoading(true);
                const response = await imageAPI.fetchFacesInAllImages(userId, auth);
                // let unconfirmed = await imageAPI.fetchFacesInAllImages(userId, auth);
                let confirmed = []
                let faces = await axios.get(`https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/person`, {
                    params: {
                        sub: userId
                    },
                    headers: {
                        'Authorization': `Bearer ${auth.IdToken}`,
                        'Content-Type': 'application/json'
                    }
                });
                // Check if response and response.data exist
                if (response && response.data && faces && faces.data) {
                    // Parse the JSON string into an array of objects
                    const parsedPeople = JSON.parse(faces.data.body);

                    // Transform each object into the `People` interface format
                    const transformedPeople = parsedPeople.map((person: { name: string; firstName: string; lastName: string; id: string; profilePictureUrl: string; }) => ({
                        person_name: person.name || `${person.firstName || ''} ${person.lastName || ''}`.trim(),
                        person_key: person.id,
                        profile_url: person.profilePictureUrl || ''
                    }));

                    // console.log(transformedPeople); // Log transformed list for debugging

                    // Set the state with transformed data
                    setFacesInImages({
                        unnamed_faces: response.data.unnamed_faces,
                        named_faces: [],
                        people: transformedPeople
                    });

                    console.log(facesInImages);
                } else {
                    throw new Error('Invalid response format');
                }
            } catch (err) {
                setError(err instanceof Error ? err.message : 'An error occurred while fetching faces');
            } finally {
                setIsLoading(false);
            }
        };

        fetchFaces();
    }, [userId, auth]);

    const handleNewPersonAdded = (person: NewPerson) => {
        setNewPeople(prev => [...prev, person]);

        // Update the facesInImages state to include the new person
        if (facesInImages) {
            setFacesInImages(prev => {
                if (!prev) return null;
                return {
                    ...prev,
                    people: [...prev.people, {
                        person_key: person.person_key,
                        person_name: person.person_name,
                        profile_url: person.profile_url
                    }]
                };
            });
        }
    };

    const renderSkeletonNamedFaces = () => {
        const skeletonNamedFaceCards = Array(5).fill(0).map((_, index) => (
            <Carousel.Slide key={index}>
                <Flex
                    direction="column"
                    align="center"
                    p="10px"
                    style={{
                        cursor: 'pointer',
                        width: '100%'
                    }}
                >
                    <Skeleton
                        circle
                        height={120}
                        width={120}
                        mb="md"
                    />
                    <Skeleton
                        height={20}
                        width="70%"
                    />
                </Flex>
            </Carousel.Slide>
        ));

        return (
            <>
                <Text size="xl" fw={500} mt="sm">Named Faces:</Text>
                <Paper
                    shadow="sm"
                    radius="md"
                    withBorder
                    p="md"
                    mt="md"
                    style={{ backgroundColor: '#f0f0f0' }}
                >
                    <Carousel
                        slideSize={{ base: '50%', sm: '33.333333%', md: '25%', lg: '20%' }}
                        slideGap="md"
                        align="start"
                        slidesToScroll={1}
                        withControls
                        loop
                    >
                        {skeletonNamedFaceCards}
                    </Carousel>
                </Paper>
            </>
        );
    };

    const renderSkeletonUnnamedFaces = () => {
        const skeletonCards = Array(6).fill(0).map((_, index) => (
            <div
                key={index}
                style={{
                    position: 'relative',
                    width: 'calc((100% - 17px) / 6)',
                    margin: '1px'
                }}
            >
                <Card
                    p={0}
                    w="100%"
                    style={{aspectRatio: '4 / 5'}}
                >
                    <Flex direction="column" h="100%">
                        <Skeleton
                            style={{flexBasis: '80%'}}
                            height="100%"
                            width="100%"
                        />
                        <Paper
                            style={{
                                flexBasis: '20%',
                                width: '100%',
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0
                            }}
                            bg="lightgray"
                        >
                            <Skeleton height={30} width="80%" mx="auto" mt="xs" />
                        </Paper>
                    </Flex>
                </Card>
            </div>
        ));

        return (
            <>
                <Text size="xl" fw={500} mt="md">Unnamed Faces:</Text>
                <Flex
                    mt="sm"
                    direction="row"
                    wrap="wrap"
                    gap={{base: '1px', sm: '1px'}}
                    justify="flex-start"
                >
                    {skeletonCards}
                </Flex>
            </>
        );
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Flex direction='column'>
            <Title order={1}>Identify Faces</Title>
            <Divider mt='xs' />
            <Blockquote color="blue" icon={<IconInfoCircle />} mt='lg' mb='lg'>
                Identifying people in photos is crucial as it helps organize images accurately, enabling easy retrieval and browsing. Moreover, it enhances the AI's ability to predict ages, facilitating the estimation of when the photos were taken based on known birthdates.
            </Blockquote>

            {isLoading ? (
                <>
                    {renderSkeletonNamedFaces()}
                    {renderSkeletonUnnamedFaces()}
                </>
            ) : (
                <SuggestedFaceContent
                    userId={userId}
                    auth={auth}
                    facesInImages={facesInImages!}
                    onNewPersonAdded={handleNewPersonAdded}
                />
            )}
        </Flex>
    );
};