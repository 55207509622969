import React, { useState } from 'react';
import { Modal, TextInput, Button, Fieldset, Flex, Image } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import {Face, FaceInImage, UnnamedFaceInImage} from '../../types/Face';

export interface AddFacesToFamilyTreeModalProps {
    onClose: () => void;
    onAddFamilyMember: (
        face: Face,
        firstName: string,
        lastName: string,
        dateOfBirth: string
    ) => void;
    face: Face,
    name: string
}

const AddNewPerson: React.FC<AddFacesToFamilyTreeModalProps> = ({
                                                                    onClose,
                                                                    onAddFamilyMember,
                                                                    face, name
                                                                }) => {
    // Split suggested name into first and last name, handling more than 2 names
    const nameParts = name?.split(' ') || [];
    const [newMember, setNewMember] = useState({
        firstName: nameParts[0] || '',
        lastName: nameParts.length > 1 ? nameParts.slice(1).join(' ') : '', // Handle multiple last name parts
        dateOfBirth: ''
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setNewMember((prevMember) => ({
            ...prevMember,
            [name]: value,
        }));
    };

    const handleDateChange = (value: Date | null) => {
        setNewMember((prevMember) => ({
            ...prevMember,
            dateOfBirth: value ? value.toLocaleDateString() : '', // Convert date to MM/DD/YYYY format
        }));
    };

    const handleSubmit = async () => {
        // Disable submit button and show loading state
        setIsSubmitting(true);
        try {
            await onAddFamilyMember(
                face,
                newMember.firstName,
                newMember.lastName,
                newMember.dateOfBirth
            );
            // Close modal after successful submission
            onClose();
        } catch (error) {
            // Handle any errors (you might want to show an error message)
            console.error('Failed to add family member:', error);
        } finally {
            // Re-enable submit button
            setIsSubmitting(false);
        }
    };

    return (
        <Modal opened onClose={onClose} size="lg" title="Add New Family Member">
            <Flex
                mt="md"
                direction="row"
                wrap="wrap"
                gap={{ base: '1px', sm: '1px' }}
                justify="center"
            >
                <div
                    key={face.face_key}
                    style={{
                        position: 'relative',
                        width: '30%',
                        cursor: 'pointer',
                        margin: '1px'
                    }}
                >
                    <Image
                        src={face.face_url}
                        alt={`face_thumbnail`}
                        fallbackSrc="https://placehold.co/400x400?text=Placeholder"
                        style={{
                            aspectRatio: 1 / 1,
                            cursor: 'pointer',
                            fit: 'fill',
                            borderRadius: '45px',
                            border: '1px solid grey',
                        }}
                    />
                </div>
            </Flex>
            <Fieldset mt="md" legend="Required Information">
                <TextInput
                    label="First Name"
                    placeholder="Enter first name"
                    value={newMember.firstName}
                    onChange={handleInputChange}
                    name="firstName"
                    required
                    style={{ marginBottom: '16px' }}
                />
                <TextInput
                    label="Last Name"
                    placeholder="Enter last name"
                    value={newMember.lastName}
                    onChange={handleInputChange}
                    name="lastName"
                    required
                    style={{ marginBottom: '16px' }}
                />
                <DateInput
                    valueFormat="MM/DD/YYYY"
                    label="Date of Birth"
                    placeholder="MM/DD/YYYY"
                    value={newMember.dateOfBirth ? new Date(newMember.dateOfBirth) : null}
                    onChange={handleDateChange}
                    required
                    style={{ marginBottom: '16px' }}
                />
            </Fieldset>
            <Flex justify={{ sm: 'right' }}>
                <Button
                    mt="lg"
                    variant="outline"
                    color="blue"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? `Adding ${newMember.firstName} ${newMember.lastName}...` : 'Add Member'}
                </Button>
            </Flex>
        </Modal>
    );
};

export default AddNewPerson;