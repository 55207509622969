// ImageSelectionGrid.tsx
import React, { useEffect, useState, useRef} from 'react';
import { Paper, Checkbox, Flex, Loader, Button, Group, Text } from '@mantine/core';
import ImageWithSkeleton from '../ImageWithSkeleton';
import { ImageData } from '../../types/ImageData';

interface ImageSelectionGridProps {
    images: ImageData[];
    selectedImages: string[];
    onImageSelect: (imageKey: string) => void;
    loading: boolean;
    hasMore: boolean;
    onLoadMore: () => void;
    enableInfiniteScroll?: boolean; // New prop to control infinite scroll
}

export const ImageSelectionGrid = ({
                                       images,
                                       selectedImages,
                                       onImageSelect,
                                       loading,
                                       hasMore,
                                       onLoadMore,
                                       enableInfiniteScroll = true // Default to true for backward compatibility
                                   }: ImageSelectionGridProps) => {

    // Handle infinite scroll
    useEffect(() => {
        // Only add scroll listener if infinite scroll is enabled
        if (!enableInfiniteScroll) return;

        const handleScroll = () => {
            if (
                window.innerHeight + document.documentElement.scrollTop
                >= document.documentElement.offsetHeight - 500
                && !loading
                && hasMore
            ) {
                onLoadMore();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading, hasMore, onLoadMore, enableInfiniteScroll]);

    return (
        <>
            <Flex
                direction="row"
                wrap="wrap"
                gap={{base: '3px', sm: '3px'}}
                justify={{sm: '3px'}}
            >
                {images.map((image) => (
                    <Paper
                        key={image.key}
                        shadow="sm"
                        p="xs"
                        style={{
                            position: 'relative',
                            width: 'calc((100% - 15px) / 5)',
                            aspectRatio: 1 / 1,
                        }}
                    >
                        <Checkbox
                            checked={selectedImages.includes(image.key)}
                            onChange={() => onImageSelect(image.key)}
                            style={{position: 'absolute', top: 5, left: 5, zIndex: 1}}
                        />
                        <ImageWithSkeleton
                            src={image.thumbnail_url}
                            alt={`Photo ${image.datetime ? `taken on ${image.datetime}` : 'with unknown date'}`}
                            onClick={() => onImageSelect(image.key)}
                        />
                    </Paper>
                ))}
            </Flex>

            {loading && (
                <Flex justify="center" mt="xl">
                    <Loader size="lg" />
                </Flex>
            )}

            {!hasMore && !loading && images.length > 0 && (
                <Text ta="center" mt="xl" mb="lg">No more images to load</Text>
            )}
        </>
    );
}