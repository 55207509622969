import React, {useState, useEffect} from 'react';
import { Carousel } from '@mantine/carousel';
import {
    Text,
    Fieldset,
    Card,
    SimpleGrid,
    Box,
    Image,
    Paper,
    Group,
    Button,
    Flex,
    Autocomplete,
    Tooltip,
    Modal,
    Avatar, Divider, Skeleton,
} from '@mantine/core';
import {IconCheck, IconX, IconTrash, IconPencil, IconStack2} from '@tabler/icons-react';
import {AuthenticationResultType} from "@aws-sdk/client-cognito-identity-provider";
import axios from "axios";
import {FaceInImage, NamedFaceInImage, UnnamedFaceInImage, FaceStack} from "../../types/Face";
import AddFacesModal from './AddFacesToFamilyTreeModal'; // Import the modal

interface People {
    person_name: string;
    person_key: string;
    profile_image_url?: string; // todo: this will eventually be required once i get rid of the named faces thing...
}

interface FaceDataFromImages {
    people: People[];
    named_faces: NamedFaceInImage[];
    unnamed_faces: UnnamedFaceInImage[];
}

interface ConfirmFacesModalContentProps {
    userId: string;
    auth: AuthenticationResultType;
    facesInImages: FaceDataFromImages;
    onNewPersonAdded: (person: { person_key: string; person_name: string, first_name: string, last_name: string, profile_url: string}) => void;
}

const API_URL = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/faces";

export function SuggestedFaceContent({
                                             userId,
                                             auth,
                                             facesInImages,
                                             onNewPersonAdded

                                         }: ConfirmFacesModalContentProps) {

    const [unnamedFaces, setUnnamedFaces] = useState<UnnamedFaceInImage[]>(facesInImages.unnamed_faces);
    const [namedFaces, setNamedFaces] = useState<NamedFaceInImage[]>(facesInImages.named_faces);
    const [confirmedFaceStacks, setConfirmedFaceStacks] = useState<FaceStack[]>([]);
    const [peopleInDatabase, setPeopleInDatabase] = useState<People[]>(facesInImages.people);
    const [expandedStack, setExpandedStack] = useState<string | null>(null);
    const [selectedStack, setSelectedStack] = useState<FaceStack | null>(null);

    // New state for family tree modal
    const [isAddFamilyTreeModalOpen, setIsAddFamilyTreeModalOpen] = useState(false);
    const [currentNewPersonData, setCurrentNewPersonData] = useState<{
        face: FaceInImage,
        name: string
    } | null>(null);


    const [editingName, setEditingName] = useState<string>("");
    const [hoveredCard, setHoveredCard] = useState<string>("");
    const [editingValue, setEditingValue] = useState<string>("");
    const [tags, setTags] = useState<People[]>([]);


    // Process named faces into stacks whenever namedFaces changes
    useEffect(() => {
        const stacks: { [key: string]: NamedFaceInImage[] } = {};

        namedFaces.forEach(face => {
            const stackKey = `${face.person_key}-${face.person_name}`;
            if (!stacks[stackKey]) {
                stacks[stackKey] = [];
            }
            stacks[stackKey].push(face);
        });

        // const newStacks: FaceStack[] = Object.values(stacks).map(faces => ({
        //     faces,
        //     stacked: true
        // }));

        // setConfirmedFaceStacks(newStacks);
    }, [namedFaces]);

    const handleAutocompleteChange = (value: string, face: UnnamedFaceInImage) => {
        setEditingValue(value);
        const lowerCaseKnownPeople = peopleInDatabase.map(person => person.person_name.toLowerCase());
        const filteredKnownPeople = peopleInDatabase
            .filter(person => person.person_name.toLowerCase().startsWith(value.toLowerCase()))
            .slice(0, 3);

        const matchesStart = lowerCaseKnownPeople.some(person => person.startsWith(value.toLowerCase()));
        if (!matchesStart) {
            const addOption: People = {
                person_name: `+ Add "${value}"`,
                person_key: face.suggested_person_key || `new-${value}`
            };
            setTags([...filteredKnownPeople, addOption]);
        } else {
            setTags(filteredKnownPeople);
        }
    };

    const handleAutoCompleteSelect = (selectedPerson: People, face: UnnamedFaceInImage) => {
        if (selectedPerson.person_name.startsWith('+ Add')) {
            const newName = selectedPerson.person_name.match(/"(.*)"/)?.[1];
            if (newName) {
                const newPerson: People = {
                    person_name: newName,
                    person_key: selectedPerson.person_key,
                }
                openAddNewPersonModal(face, newPerson);
            }
        } else {
            nameAsExistingPerson(face, selectedPerson);
        }
        setEditingName(""); // Clear editing state after selection
        setEditingValue(""); // Clear editing value
    };

    const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, face: UnnamedFaceInImage) => {
        if (event.key === 'Enter') {
            const matchingPerson = tags.find(person => person.person_name.toLowerCase() === editingValue.toLowerCase());
            if (matchingPerson) {
                handleAutoCompleteSelect(matchingPerson, face);
            } else if (editingValue.trim()) {
                // Handle as new person if no match
                const newPerson: People = {
                    person_name: editingValue,
                    person_key: face.suggested_person_key || `new-${editingValue}`
                };
                openAddNewPersonModal(face, newPerson);
            }
            setEditingName(""); // Clear editing state
            setEditingValue(""); // Clear editing value
        } else if (event.key === 'Tab') {
            event.preventDefault();
            if (tags.length > 0) {
                setEditingValue(tags[0].person_name);
            }
        }
    };

    const makeApiCall = async (personKey: string, faces: Array<{
        key: string,
        rekognition_id: string
    }>, action: 'confirm' | 'deny' | 'untrack' | 'label' | 'name') => {
        try {
            await axios.patch(API_URL, {
                uid: userId,
                key: personKey,
                faces,
                action,
            }, {
                headers: {
                    'Authorization': `Bearer ${auth.IdToken}`,
                    'Content-Type': 'application/json'
                }
            });
        } catch (error) {
            console.error(`Error ${action}ing face:`, error);
            throw new Error(`An error occurred while ${action}ing the face. Please try again.`);
        }
    };

    // Optimistic update function to remove face immediately
    const optimisticRemoveFace = (face: UnnamedFaceInImage) => {
        setUnnamedFaces(prev => prev.filter(f => f.face_key !== face.face_key));
    };

    // Rollback function to add face back if API call fails
    const rollbackFace = (face: UnnamedFaceInImage) => {
        setUnnamedFaces(prev => [...prev, face]);
    };

    const confirmUnnamedFace = async (face: UnnamedFaceInImage) => {
        if (!face.suggested_person_key) return;

        // Immediately remove the face optimistically
        optimisticRemoveFace(face);

        try {
            await makeApiCall(
                face.suggested_person_key,
                [{key: face.face_key, rekognition_id: face.face_rekognition_id}],
                'confirm'
            );

            // Convert to named face
            const namedFace: NamedFaceInImage = {
                ...face,
                confirmed: true,
                person_key: face.suggested_person_key,
                person_name: face.suggested_person_name || "Unknown"
            };

            setNamedFaces(prev => [...prev, namedFace]);
        } catch (error) {
            // If API call fails, add the face back
            rollbackFace(face);
            alert(error);
        }
    };

    const denyUnnamedFace = async (face: UnnamedFaceInImage) => {
        if (!face.suggested_person_key) return;

        // Immediately remove the face optimistically
        optimisticRemoveFace(face);

        try {
            await makeApiCall(
                face.suggested_person_key,
                [{key: face.face_key, rekognition_id: face.face_rekognition_id}],
                'deny'
            );

            // Optionally, you might want to update the face with undefined suggestions
            setUnnamedFaces(prev => {
                const updatedFaces = prev.map(f =>
                    f.face_key === face.face_key
                        ? {...f, suggested_person_name: undefined, suggested_person_key: undefined}
                        : f
                );
                return updatedFaces;
            });
        } catch (error) {
            // If API call fails, add the face back
            rollbackFace(face);
            alert(error);
        }
    };

    const untrackFace = async (face: UnnamedFaceInImage) => {
        // Immediately remove the face optimistically
        optimisticRemoveFace(face);

        try {
            await makeApiCall(
                face.suggested_person_key || '',
                [{key: face.face_key, rekognition_id: face.face_rekognition_id}],
                'untrack'
            );
        } catch (error) {
            // If API call fails, add the face back
            rollbackFace(face);
            alert(error);
        }
    };

    const nameAsExistingPerson = async (face: UnnamedFaceInImage, person: People) => {
        // Immediately remove the face optimistically
        optimisticRemoveFace(face);

        try {
            await makeApiCall(
                person.person_key,
                [{key: face.face_key, rekognition_id: face.face_rekognition_id}],
                'label'
            );

            // Convert to named face
            const namedFace: NamedFaceInImage = {
                ...face,
                confirmed: true,
                person_key: person.person_key,
                person_name: person.person_name
            };

            setNamedFaces(prev => [...prev, namedFace]);
        } catch (error) {
            // If API call fails, add the face back
            rollbackFace(face);
            alert(error);
        }
    };



    const handleAddFamilyMember = async (
        face: FaceInImage,
        firstName: string,
        lastName: string,
        dateOfBirth: string
    ) => {

        // todo: do something to the modal to signal loading. Maybe disable button? grey out with loading?

        try {
            // Create a new API endpoint for adding a person
            const response = await axios.post(
                "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/person",
                {
                    uid: userId,
                    first_name: firstName,
                    last_name: lastName,
                    dob: dateOfBirth,
                    key: face.face_key
                },
                {
                    headers: {
                        'Authorization': `Bearer ${auth.IdToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            console.log(response.data);
            let newPerson: People = JSON.parse(response.data.body);

            // Name the face with the newly created person
            nameAsExistingPerson(face as UnnamedFaceInImage, newPerson);

            // onNewPersonAddedCallback
            onNewPersonAdded({ person_key: newPerson.person_key, person_name: newPerson.person_name, first_name: firstName, last_name: lastName, profile_url: newPerson.profile_image_url || ''})

            // Close the modal
            setIsAddFamilyTreeModalOpen(false);
            setCurrentNewPersonData(null);
        } catch (error) {
            console.error('Error adding family member:', error);
            alert('Failed to add family member. Please try again.');
        }
    };

    const openAddNewPersonModal = async (face: UnnamedFaceInImage, person: People) => {
        // Instead of immediately creating a new person, open the family tree modal
        setCurrentNewPersonData({
            face: face,
            name: person.person_name
        });
        setIsAddFamilyTreeModalOpen(true);
    };

    // const renderStackCounter = (face: NamedFaceInImage, stackSize: number) => (
    //     <Group
    //         gap={'3px'}
    //         style={{
    //             position: 'absolute',
    //             top: 5,
    //             right: 5,
    //             backgroundColor: 'rgba(48, 48, 48, 1.0)',
    //             borderRadius: '15%',
    //             cursor: 'pointer',
    //             padding: '2px 4px'
    //         }}
    //         onClick={(e) => {
    //             e.stopPropagation();
    //             const stack = confirmedFaceStacks.find(s => s.faces[0].person_key === face.person_key);
    //             if (stack) {
    //                 setSelectedStack(stack);
    //             }
    //         }}
    //     >
    //         <Text size="md" c="white" w="bold" pl={'2px'}>
    //             {stackSize}
    //         </Text>
    //         <IconStack2 size={20} color="white"/>
    //     </Group>
    // );

    const renderFaceCard = (face: NamedFaceInImage, stackSize: number = 1) => (
        <Carousel.Slide key={face.face_key}>
            <Flex
                direction="column"
                align="center"
                p="10px"
                style={{
                    cursor: 'pointer',
                    width: '100%'
                }}
                onClick={() => {
                    // const stack = confirmedFaceStacks.find(s => s.faces[0].person_key === face.person_key);
                    // if (stack && stack.faces.length > 1) {
                    //     setSelectedStack(stack);
                    // }
                }}
            >
                <Avatar
                    src={face.face_url}
                    size={120}
                    radius="50%"
                    mb="md"
                />
                <Text
                    size="md"
                    w="bold"
                    c="black"
                    // ta="center"
                >
                    {face.person_name}
                </Text>
            </Flex>
        </Carousel.Slide>
    );

    const FaceStackModal = () => (
        <Modal
            opened={!!selectedStack}
            onClose={() => setSelectedStack(null)}
            size="lg"
            centered
            styles={{
                body: {
                    padding: 0,  // Remove default padding
                    overflow: 'hidden' // Prevent horizontal scrolling
                },
                content: {
                    width: '100%', // Ensure full width
                    maxWidth: '100%' // Prevent extra space
                }
            }}
        >
            {selectedStack && (
                <Flex
                    direction="column"
                    align="center"
                    p="md"  // Add padding inside the flex container
                    style={{
                        width: '100%',
                        boxSizing: 'border-box'
                    }}
                >
                    <Avatar
                        src={selectedStack.faces[0].face_url}
                        size={160}
                        radius="50%"
                    />
                    <Text
                        size="xl"
                        fw={700}
                        mt="md"
                        mb="xl"
                        ta="center"
                    >
                        {/*{selectedStack.faces[0].person_name}*/}
                    </Text>
                    <SimpleGrid
                        cols={{ base: 3, sm: 4, md: 4 }}  // Responsive grid
                        spacing="md"
                        style={{ width: '100%' }}
                    >
                        {selectedStack.faces.map((face) => (
                            <Card
                                key={face.face_key}
                                p={0}
                                style={{
                                    width: '100%'
                                }}
                            >
                                <Image
                                    src={face.face_url}
                                    height="100%"
                                    width="100%"
                                    fit="cover"
                                />
                            </Card>
                        ))}
                    </SimpleGrid>
                </Flex>
            )}
        </Modal>
    );

    return (
        <>
            {confirmedFaceStacks.length > 0 && (
                <>
                <Text size="xl" fw={500} mt="sm">Named Faces:</Text>
                <Paper
                    key="confirmedFaces"
                    shadow="sm"
                    radius="md"
                    withBorder
                    p="md"
                    mt="md"
                    style={{ backgroundColor: '#f0f0f0' }}
                >
                    <Carousel
                        slideSize={{ base: '50%', sm: '33.333333%', md: '25%', lg: '20%' }}
                        slideGap="md"
                        align="start"
                        slidesToScroll={1}
                        withControls
                        loop
                        styles={{
                            control: {
                                '&[data-inactive]': {
                                    opacity: 0,
                                    cursor: 'default',
                                },
                            },
                        }}
                    >
                        {/*{confirmedFaceStacks.map((stack) => (*/}
                        {/*    renderFaceCard(stack.faces[0], stack.faces.length)*/}
                        {/*))}*/}
                    </Carousel>
                </Paper>
                </>
            )}
            <FaceStackModal />

            {/*todo: please make the fieldset legend text larger.*/}
            {/*todo: only render this fieldset component/unnamed faces section if confirmed faces > 1*/}
            <Text size="xl" fw={500} mt="md">Unnamed Faces:</Text>
                <Flex
                    mt="sm"
                    direction="row"
                    wrap="wrap"
                    gap={{base: '1px', sm: '1px'}}
                    justify="flex-start"
                >
                    {unnamedFaces.map((face) => (
                        <div key={face.face_key} style={{
                            position: 'relative',
                            width: 'calc((100% - 17px) / 6)',
                            cursor: 'pointer',
                            margin: '1px'
                        }}>
                            <Card
                                p={0}
                                w="100%"
                                style={{aspectRatio: '4 / 5'}}
                                onMouseEnter={() => setHoveredCard(face.face_key)}
                                onMouseLeave={() => setHoveredCard("")}

                            >
                                <Flex direction="column" h="100%">
                                    <Box style={{flexBasis: '80%', position: 'relative'}}>
                                        <Image
                                            src={face.face_url}
                                            height="100%"
                                            width="100%"
                                            fit="cover"
                                        />
                                    </Box>
                                    <Paper
                                        style={{
                                            flexBasis: '20%',
                                            width: '100%',
                                            borderTopLeftRadius: 0,
                                            borderTopRightRadius: 0
                                        }}
                                        bg="lightgray"
                                    >
                                        {editingName === face.face_key ? (
                                            <Autocomplete
                                                placeholder="Enter name"
                                                value={editingValue}
                                                onChange={(value) => handleAutocompleteChange(value, face)}
                                                data={tags.map(person => person.person_name)}
                                                onKeyDown={(e) => handleInputKeyDown(e, face)}
                                                onSubmit={(item) => {
                                                    const selectedPerson = tags.find(p => p.person_name === editingValue);
                                                    if (selectedPerson) {
                                                        handleAutoCompleteSelect(selectedPerson, face);
                                                    }
                                                }}
                                                onFocus={(e) => {
                                                    e.target.select();
                                                    setEditingValue(face.suggested_person_name || "");
                                                }}
                                                styles={{
                                                    root: {
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                        height: '100%',
                                                        paddingLeft: '3px',
                                                        paddingRight: '3px',
                                                        zIndex: 3
                                                    },
                                                    input: {
                                                        textAlign: 'center'
                                                    }
                                                }}
                                                autoFocus
                                            />
                                        ) : (
                                            hoveredCard === face.face_key ? (
                                                <Group grow preventGrowOverflow={false} wrap="nowrap" h="100%"
                                                       style={{paddingLeft: '5%', paddingRight: '5%'}}>
                                                    <Flex justify="center" align="center" w="15%" h="100%">
                                                        <Tooltip
                                                            label={face.suggested_person_name ? `This is not ${face.suggested_person_name}` : 'Untrack this face'}
                                                            withArrow
                                                            openDelay={500}
                                                            position="bottom"
                                                        >
                                                            <Button
                                                                variant="transparent"
                                                                color="dark"
                                                                onClick={() => face.suggested_person_name ? denyUnnamedFace(face) : untrackFace(face)}
                                                                p={0}
                                                                h="100%"
                                                                w="100%"
                                                            >
                                                                {face.suggested_person_name ? <IconX size={16}/> :
                                                                    <IconTrash size={16}/>}
                                                            </Button>
                                                        </Tooltip>
                                                    </Flex>
                                                    <Flex justify="center" align="center" w="70%" h="100%">
                                                        <Text
                                                            size="sm"
                                                            ta="center"
                                                            c="dark"
                                                            style={{cursor: 'pointer'}}
                                                            onClick={() => setEditingName(face.face_key)}
                                                        >
                                                            {face.suggested_person_name ? `${face.suggested_person_name}?` : "?"}
                                                        </Text>
                                                    </Flex>
                                                    <Flex justify="center" align="center" w="15%" h="100%">
                                                        {face.suggested_person_name ? (
                                                            <Tooltip
                                                                label={`Confirm face as ${face.suggested_person_name}`}
                                                                withArrow
                                                                openDelay={500}
                                                                position="bottom"
                                                            >
                                                                <Button
                                                                    variant="transparent"
                                                                    color="dark"
                                                                    onClick={() => confirmUnnamedFace(face)}
                                                                    p={0}
                                                                    h="100%"
                                                                    w="100%"
                                                                >
                                                                    <IconCheck size={16}/>
                                                                </Button>
                                                            </Tooltip>
                                                        ) : (
                                                            <Button
                                                                variant="transparent"
                                                                color="lightgray"
                                                                onClick={() => setEditingName(face.face_key)}
                                                                p={0}
                                                                h="100%"
                                                                w="100%"
                                                            >
                                                                <IconPencil size={16}/>
                                                            </Button>
                                                        )}
                                                    </Flex>
                                                </Group>
                                            ) : (
                                                <Flex justify="center" align="center" h="100%">
                                                    <Text
                                                        size="sm"
                                                        ta="center"
                                                        c="dark"
                                                        style={{cursor: 'pointer'}}
                                                        onClick={() => setEditingName(face.face_key)}
                                                    >
                                                        {face.suggested_person_name ? `${face.suggested_person_name}?` : "?"}
                                                    </Text>
                                                </Flex>
                                            )
                                        )}
                                    </Paper>
                                </Flex>
                            </Card>
                        </div>
                    ))}
                </Flex>
            {currentNewPersonData && (
                <AddFacesModal
                    onClose={() => {
                        setIsAddFamilyTreeModalOpen(false);
                        setCurrentNewPersonData(null);
                    }}
                    onAddFamilyMember={handleAddFamilyMember}
                    face={currentNewPersonData.face}
                    name={currentNewPersonData.name}
                />
            )}
        </>
    );
}