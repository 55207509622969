import React, { useState } from 'react';
import { Modal, TextInput, Button, Fieldset, Flex, Image } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import {FaceInImage, UnnamedFaceInImage} from '../../types/Face';

export interface AddFacesToFamilyTreeModalProps {
    onClose: () => void;
    onAddFamilyMember: (
        face: FaceInImage,
        firstName: string,
        lastName: string,
        dateOfBirth: string
    ) => void;
    face: FaceInImage,
    name: string
}

const AddFacesModal: React.FC<AddFacesToFamilyTreeModalProps> = ({
                                                                     onClose,
                                                                     onAddFamilyMember,
                                                                     face, name
                                                                 }) => {
    // Split suggested name into first and last name if possible
    const nameParts = name?.split(' ') || [];
    const [newMember, setNewMember] = useState({
        firstName: nameParts[0] || '',
        lastName: nameParts[1] || '', // todo: this should probably be the rest of the name parts in case of more than 2 names
        dateOfBirth: ''
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setNewMember((prevMember) => ({
            ...prevMember,
            [name]: value,
        }));
    };

    const handleDateChange = (value: Date | null) => {
        setNewMember((prevMember) => ({
            ...prevMember,
            dateOfBirth: value ? value.toLocaleDateString() : '', // Convert date to MM/DD/YYYY format
        }));
    };

    const handleSubmit = () => {
        onAddFamilyMember(
            face,
            newMember.firstName,
            newMember.lastName,
            newMember.dateOfBirth
        );
    };

    return (
        <Modal opened onClose={onClose} size="lg" title="Add New Family Member">
            <Flex
                mt="md"
                direction="row"
                wrap="wrap"
                gap={{ base: '1px', sm: '1px' }}
                justify="center"
            >
                {/*{person.faces.slice(0, 1).map((face) => (*/}
                <div
                    key={face.face_key}
                    style={{
                        position: 'relative',
                        width: '30%',
                        cursor: 'pointer',
                        margin: '1px'
                    }}
                >
                    <Image
                        src={face.face_url}
                        alt={`face_thumbnail`}
                        fallbackSrc="https://placehold.co/400x400?text=Placeholder"
                        style={{
                            aspectRatio: 1 / 1,
                            cursor: 'pointer',
                            fit: 'fill',
                            borderRadius: '45px', // Rounded corners
                            border: '1px solid grey', // Grey border
                        }}
                    />
                </div>
                {/*))}*/}
            </Flex>
            <Fieldset mt="md" legend="Required Information">
                <TextInput
                    label="First Name"
                    placeholder="Enter first name"
                    value={newMember.firstName}
                    onChange={handleInputChange}
                    name="firstName"
                    required
                    style={{ marginBottom: '16px' }}
                />
                <TextInput
                    label="Last Name"
                    placeholder="Enter last name"
                    value={newMember.lastName}
                    onChange={handleInputChange}
                    name="lastName"
                    required
                    style={{ marginBottom: '16px' }}
                />
                <DateInput
                    valueFormat="MM/DD/YYYY"
                    label="Date of Birth"
                    placeholder="MM/DD/YYYY"
                    value={newMember.dateOfBirth ? new Date(newMember.dateOfBirth) : null}
                    onChange={handleDateChange}
                    required
                    style={{ marginBottom: '16px' }}
                />
            </Fieldset>
            <Flex justify={{ sm: 'right' }}>
                <Button mt="lg" variant="outline" color="blue" onClick={handleSubmit}>
                    Add Member
                </Button>
            </Flex>
        </Modal>
    );
};

export default AddFacesModal;