import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ActionIcon, Box, Button, Group, Select, Stack, TextInput} from '@mantine/core';
import {DateInput} from '@mantine/dates';
import {IconPlus, IconTrash} from '@tabler/icons-react';
import {LifeEvent, LifeEventType, LifeEventTypes, Person} from "../../types/Person";
import {useDebouncedCallback} from 'use-debounce';

interface LifeEventsPanelProps {
    member: Person;
    onUpdate: (updatedMember: Person) => Promise<void>;
    isUpdating?: boolean;
}

export const LifeEventsPanel: React.FC<LifeEventsPanelProps> = React.memo(({
                                                                               member,
                                                                               onUpdate,
                                                                               isUpdating
                                                                           }) => {
    const DEBUG = true; // Toggle debugging

    const debugLog = (...args: any[]) => {
        if (DEBUG) {
            // console.log('[LifeEventsPanel]', ...args);
        }
    };

    const [lifeEvents, setLifeEvents] = useState<LifeEvent[]>(() => {
        const birthEvent: LifeEvent = {
            type: LifeEventType.Birth,
            date: member.dateOfBirth ? new Date(member.dateOfBirth) : null, // todo: if date has time zone - strip
            isLocked: true
        };
        debugLog('Initializing life events:', [birthEvent, ...(member.lifeEvents || [])]);
        return [birthEvent, ...(member.lifeEvents || [])];
    });

    // Track incomplete events separately
    const [incompleteEvents, setIncompleteEvents] = useState<Record<number, boolean>>({});
    const lastSavedRef = useRef<LifeEvent[]>([]);
    const isSavingRef = useRef(false);

    const eventTypes = LifeEventTypes;

    const isEventComplete = useCallback((event: LifeEvent): boolean => {
        const complete = Boolean(event.type && event.date);
        debugLog('Checking event completeness:', { event, complete });
        return complete;
    }, []);

    const hasUnsavedChanges = useCallback(() => {
        const currentEvents = lifeEvents.slice(1); // Exclude birth event
        const lastSaved = lastSavedRef.current.slice(1);

        const changes = currentEvents.some((event, index) => {
            if (!isEventComplete(event)) return false;
            const savedEvent = lastSaved[index];

            const hasChanges = !savedEvent ||
                event.type !== savedEvent.type ||
                event.date?.toISOString() !== savedEvent?.date?.toISOString() ||
                event.description !== savedEvent.description;

            debugLog('Checking for changes:', {
                index,
                current: event,
                saved: savedEvent,
                hasChanges
            });

            return hasChanges;
        });

        debugLog('Unsaved changes check result:', changes);
        return changes;
    }, [lifeEvents, isEventComplete]);

    const saveChanges = async () => {
        if (isSavingRef.current) {
            debugLog('Save already in progress, skipping');
            return;
        }

        const validEvents = lifeEvents
            .slice(1) // Remove birth event
            .filter(isEventComplete);

        debugLog('Attempting to save events:', validEvents);
        isSavingRef.current = true;

        try {
            const updatedMember = {
                ...member,
                lifeEvents: validEvents
            };
            debugLog('Calling onUpdate with:', updatedMember);
            await onUpdate(updatedMember);

            debugLog('Save successful, updating lastSavedRef');
            lastSavedRef.current = lifeEvents;
        } catch (error) {
            console.error('Failed to save updates:', error);
            debugLog('Save failed, reverting to last saved state');
            setLifeEvents(lastSavedRef.current);
        } finally {
            isSavingRef.current = false;
        }
    };

    const debouncedSave = useDebouncedCallback(
        () => {
            debugLog('Debounced save triggered');
            if (hasUnsavedChanges()) {
                debugLog('Unsaved changes detected, initiating save');
                saveChanges();
            } else {
                debugLog('No unsaved changes, skipping save');
            }
        },
        3000, // Reduced from 3000 for more responsive debugging
        { maxWait: 30000 } // Reduced from 5000
    );

    const handleAddEvent = useCallback(() => {
        setLifeEvents(prev => [...prev, { type: '', date: null, description: '' }]);
        setIncompleteEvents(prev => ({
            ...prev,
            [lifeEvents.length]: true
        }));
    }, [lifeEvents.length]);

    const handleRemoveEvent = useCallback((index: number) => {
        if (index === 0) return; // Prevent removal of birth event

        setLifeEvents(prev => prev.filter((_, i) => i !== index));
        setIncompleteEvents(prev => {
            const next = { ...prev };
            delete next[index];
            // Adjust indices for remaining events
            const adjusted: Record<number, boolean> = {};
            Object.keys(next).forEach(key => {
                const numKey = parseInt(key);
                if (numKey > index) {
                    adjusted[numKey - 1] = true;
                } else {
                    adjusted[numKey] = true;
                }
            });
            return adjusted;
        });

        // Only trigger save if removing a complete event
        if (!incompleteEvents[index]) {
            debouncedSave();
        }
    }, [debouncedSave, incompleteEvents]);

    const handleUpdateEvent = useCallback((index: number, field: keyof LifeEvent, value: any) => {
        debugLog(`Event update triggered:`, { index, field, value });

        setLifeEvents(prev => {
            const updated = prev.map((event, i) =>
                i === index ? { ...event, [field]: value } : event
            );

            const updatedEvent = updated[index];
            debugLog('Updated event:', updatedEvent);

            setIncompleteEvents(prev => {
                const next = { ...prev };
                if (isEventComplete(updatedEvent)) {
                    debugLog('Event is now complete, removing from incomplete list');
                    delete next[index];
                } else {
                    debugLog('Event is incomplete, adding to incomplete list');
                    next[index] = true;
                }
                return next;
            });

            return updated;
        });

        if (field === 'description' || isEventComplete({ ...lifeEvents[index], [field]: value })) {
            debugLog('Triggering debounced save due to valid update');
            debouncedSave();
        }
    }, [lifeEvents, debouncedSave, isEventComplete]);

    const handleBlur = useCallback((index: number) => {
        debugLog('Input blur detected:', { index, incompleteEvents });
        if (!incompleteEvents[index] && hasUnsavedChanges()) {
            debugLog('Complete event detected on blur, forcing immediate save');
            debouncedSave.flush();
        }
    }, [incompleteEvents, hasUnsavedChanges, debouncedSave]);

    // Rest of your component remains the same...

    return (
        <Box mt={20} p={20}>
            <Stack gap="md">
                {lifeEvents.map((event, index) => (
                    <Group key={index} align="flex-start">
                        <Select
                            data={eventTypes}
                            value={event.type}
                            onChange={(value) => handleUpdateEvent(index, 'type', value)}
                            onBlur={() => handleBlur(index)}
                            placeholder="Select event type"
                            style={{ flex: 1 }}
                            disabled={event.isLocked || isUpdating}
                        />
                        <DateInput
                            value={event.date}
                            onChange={(value) => handleUpdateEvent(index, 'date', value)}
                            onBlur={() => handleBlur(index)}
                            placeholder="Event date"
                            style={{ flex: 1 }}
                            disabled={event.isLocked || isUpdating}
                        />
                        <TextInput
                            value={event.description || ''}
                            onChange={(e) => handleUpdateEvent(index, 'description', e.currentTarget.value)}
                            onBlur={() => handleBlur(index)}
                            placeholder="Event description (optional)"
                            style={{ flex: 2 }}
                            disabled={isUpdating}
                        />
                        {!event.isLocked && (
                            <ActionIcon
                                color="red"
                                onClick={() => handleRemoveEvent(index)}
                                variant="outline"
                                disabled={isUpdating}
                            >
                                <IconTrash size={16} />
                            </ActionIcon>
                        )}
                    </Group>
                ))}
                <Button
                    onClick={handleAddEvent}
                    leftSection={<IconPlus size={16} />}
                    variant="outline"
                    disabled={isUpdating}
                >
                    Add Life Event
                </Button>
            </Stack>
        </Box>
    );
});

LifeEventsPanel.displayName = 'LifeEventsPanel';